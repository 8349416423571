import React from "react"

import HomeoptionsComponent from "../components/Application/HomeoptionsComponent"
import ProtectedRouteLayout from "../components/Layout/ProtectedRouteLayout"

const Home = () => {
  return (
    <ProtectedRouteLayout>
      <HomeoptionsComponent />
    </ProtectedRouteLayout>
  )
}

export default Home
