import React, { useContext } from "react"

import { navigate } from "gatsby"

import PropTypes from "prop-types"

import Available from "../../assets/images/Icons/Available.svg"
import BlueLocker from "../../assets/images/Icons/blue-locker.svg"
import GreyLocker from "../../assets/images/Icons/locker.svg"
import Tick from "../../assets/images/Icons/tick.svg"
import { ApplicationContext, AuthenticationContext } from "../../context/"
import orderTypes from "../../utils/orderTypes.json"
import AppNavbar from "./AppNavbar"
import HomeInfo from "./HomeInfo"

import "./HomeoptionsComponent.scss"

const HomeOptions = () => {
  const { isLoadingRequest, setIsLoadingRequest } = useContext(
    AuthenticationContext
  )
  const [isInfoOpen, setIsInfoOpen] = React.useState(false)
  const [selected, setSelected] = React.useState([])
  const {
    fetchOrders,
    newOrder,
    cancelPendingOrdersDropOff,
    getAvailableLockers,
    availableLockers,
  } = useContext(ApplicationContext)
  const isLockersAvailable = availableLockers > 0

  React.useEffect(() => {
    cancelPendingOrdersDropOff()
    fetchOrders("pending", false)
  }, [])

  const handleSelect = (item) => {
    if (selected.includes(item.value)) {
      setSelected(selected.filter((order) => order !== item.value))
    } else {
      setSelected((prev) => [...prev, item.value])
    }
  }

  const handleNextStep = async () => {
    setIsLoadingRequest(true)
    const orderPromises = newOrder(selected)
    Promise.all(orderPromises).then((response) => {
      localStorage.setItem("selectedTypes", JSON.stringify(selected))
      fetchOrders("pending", true, selected)
    })
  }

  const WashButton = ({ text, icon, svgIcon, isSelected }) => {
    let className = "homescreen-component__body__buttongroup__button"
    if (isSelected)
      className += " homescreen-component__body__buttongroup__button--selected"
    return (
      <div className={className}>
        <div className="button">
          <div className="button__top">
            <img src={svgIcon}></img>
          </div>
          <div className="button__bottom">
            <div>
              <img src={icon}></img>
            </div>
            <span>{text}</span>
          </div>
        </div>
      </div>
    )
  }

  WashButton.propTypes = {
    icon: PropTypes.node.isRequired,
    id: PropTypes.number.isRequired,
    isSelected: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    svgIcon: PropTypes.node.isRequired,
    text: PropTypes.string.isRequired,
  }

  React.useEffect(() => {
    getAvailableLockers()
  }, [])

  return (
    <div className="homescreen-container">
      <div className="homescreen-header">
        <AppNavbar setIsInfoOpen={setIsInfoOpen} isInfoOpen={isInfoOpen} />
      </div>
      <div className="homescreen-component">
        <div className="homescreen-component__body">
          {!isLoadingRequest ? (
            <>
              <div className="homescreen-component__body__welcome">
                <p>What are you looking to do?</p>
              </div>
              <br />
              <div className="homescreen-component__body__options">
                {orderTypes.map((item, i) => (
                  <button
                    key={i}
                    type="button"
                    className={
                      selected.includes(item.value)
                        ? "homescreen-component__body__options__option homescreen-component__body__options__option--selected"
                        : "homescreen-component__body__options__option"
                    }
                    onClick={() => handleSelect(item)}
                  >
                    {item.option}
                  </button>
                ))}
              </div>
              <div className="homescreen-component__body__lockers-note">
                <div className="homescreen-component__body__lockers-note__icon">
                  <img
                    src={isLockersAvailable ? BlueLocker : GreyLocker}
                    alt=""
                    className="locker"
                  />
                  <img
                    src={isLockersAvailable ? Tick : Available}
                    alt=""
                    className="tick"
                  />
                </div>
                {isLockersAvailable ? (
                  <p className="homescreen-component__body__lockers-note__text">
                    Currently there&apos;s <span>{availableLockers}</span>{" "}
                    lockers available at your washroom.
                  </p>
                ) : (
                  <p className="homescreen-component__body__lockers-note__text">
                    Sorry, all lockers are currently in use at your washroom.
                    Try again soon!
                  </p>
                )}
              </div>
              <div className="homescreen-component__body__message">
                <p>Whoops... Forgot my washing</p>
              </div>
              <button
                type="button"
                disabled={isLoadingRequest || !selected.length}
                className="homescreen-component__body__logout homescreen-component__body__logout--next"
                onClick={() => handleNextStep()}
              >
                Next Step
              </button>
              <button
                type="button"
                disabled={isLoadingRequest}
                className="homescreen-component__body__logout"
                onClick={() => navigate(-1)}
              >
                Back
              </button>
            </>
          ) : (
            <div className="lds-facebook">
              <div></div>
              <div></div>
              <div></div>
            </div>
          )}
        </div>
      </div>
      <div className="homescreen-component__email-link">
        <a href="mailto:hello@wrlaundry.com">hello@wrlaundry.com</a>
      </div>
      {isInfoOpen && <HomeInfo setIsInfoOpen={setIsInfoOpen} />}
    </div>
  )
}

export default HomeOptions
